<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/template/list' }">列表</el-breadcrumb-item>
                <el-breadcrumb-item>详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <el-row>
                <el-col :span="16">
                    <el-form ref="form" :model="form" size="medium" :label-width="this.env.label_width">
                        <el-form-item label="活动名称">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="活动名称">
                            123465
                        </el-form-item>
                        <el-form-item label="地区选择">
                            <Region v-model="form.region_code" v-on:region="getregion"/>
                        </el-form-item>
                        <el-form-item label="活动区域">
                            <el-select v-model="form.region" placeholder="请选择活动区域">
                                <el-option label="区域一" value="shanghai"></el-option>
                                <el-option label="区域二" value="beijing"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="活动时间">
                            <el-col :span="11">
                                <el-date-picker type="date" placeholder="选择日期" v-model="form.date1"
                                                style="width: 100%;"></el-date-picker>
                            </el-col>
                            <el-col class="line" :span="2">-</el-col>
                            <el-col :span="11">
                                <el-time-picker placeholder="选择时间" v-model="form.date2"
                                                style="width: 100%;"></el-time-picker>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="即时配送">
                            <el-switch v-model="form.delivery"></el-switch>
                        </el-form-item>
                        <el-form-item label="活动性质">
                            <el-checkbox-group v-model="form.type">
                                <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                                <el-checkbox label="地推活动" name="type"></el-checkbox>
                                <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                                <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <el-form-item label="特殊资源">
                            <el-radio-group v-model="form.resource">
                                <el-radio label="线上品牌商赞助"></el-radio>
                                <el-radio label="线下场地免费"></el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="活动形式">
                            <el-input type="textarea" v-model="form.desc" maxlength="20" show-word-limit
                                      rows="3"></el-input>
                        </el-form-item>
                        <el-form-item label="编辑器test">
                            <!-- 富文本编辑框 -->
                            <Wangeditor v-model="testaaa" idindex="1" seewWidth="50%"/>
                        </el-form-item>
                        <el-form-item label="上传">
                            <Qnupload v-model="form.img_url" :sum="10"/>
                        </el-form-item>
                        <el-form-item label="视频">
                            <Qnupload v-model="form.video1" type="video"/>
                        </el-form-item>

                        <el-form-item label="上传2">
                            <Qnupload v-model="form.img_url1"/>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" size="medium" @click="onSubmit">保存</el-button>
                            <el-button>取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
import Region from '@/components/Region'

export default {
    components: {
        Wangeditor,
        Qnupload,
        Region,
    },
    data() {
        return {
            testaaa: '',
            page_name: '表单',
            phoneEditor: '',
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
                img_url: [],
                img_url1: '',
                video1: '',
                province: '',
                city: '',
                county: '',
                region_code: '310101',
            }
        }
    },
    // 创建
    created() {

    },
    // 安装
    mounted() {

    },
    methods: {
        // 地址
        getregion(region_date) {
            console.log('选择的城市信息', region_date)
        },
        onSubmit() {
            // console.log('submit!');
            // console.log(this.testaaa)
            console.log(this.form)
            this.$message({
                message: '点击了保存', type: 'success',
                duration: this.env.message_duration,
                onClose: () => {
                    console.log(111)
                }
            });
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
